import React, { useState } from 'react';
import Slider from 'react-slick';
import slide1 from '../images/Slide1.jpg';
import slide2 from '../images/Slide2.jpg';
import slide3 from '../images/Slide3.jpg';
import slide4 from '../images/Slide4.JPEG';
import slide5 from '../images/Slide5.jpg';
import slide6 from '../images/Slide6.jpg';
import FullImageModal from './FullImageModal';
import '../App.css';

function Carousel() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 5, // Show multiple narrow strips at once
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10px",
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  const images = [slide1, slide2, slide3, slide4, slide5, slide6];

  return (
    <section>
      <div className="w-full flex flex-col items-center mt-10 mb-10">
        <div className="carousel-container mx-auto">
          <Slider {...carouselSettings}>
            {images.map((image, index) => (
              <div key={index} className="carousel-panel">
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="carousel-strip"
                  onClick={() => handleImageClick(image)}
                />
              </div>
            ))}
          </Slider>
        </div>

        {/* Full Image Modal */}
        {isModalOpen && (
          <FullImageModal image={currentImage} closeModal={closeModal} />
        )}
      </div>
    </section>
  );
}

export default Carousel;
