import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faEnvelope, faMessage, faHandshake } from '@fortawesome/free-solid-svg-icons';


function Navbar({ handleScrollToSection }) {
  return (
    <nav className="text-white fixed w-full top-0 left-0 z-50">
      <div className="flex justify-center items-center mt-5">
        <div className="flex w-[100%] sm:w-[500px] justify-center bg-[#FF3C3C] rounded-3xl items-center h-16 ">
          <div className="">
            <div className="flex items-baseline sm:space-x-4">
              <button
                className="text-white hover:bg-[#ff5f5f] px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => handleScrollToSection('about')}
              >
                <div className='font-bold flex flex-row-reverse justify-center items-center gap-2'>
                    <span className=''>About</span>
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="fa-fw text-2xl text-white"
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                    />
                </div>
              </button>
              <button
                className="text-white hover:bg-[#ff5f5f] px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => handleScrollToSection('sponsors')}
              >
                <div className='font-bold flex flex-row-reverse justify-center items-center gap-2'>
                    <span>Sponsors</span>
                    <FontAwesomeIcon
                      icon={faHandshake}
                      className="fa-fw text-2xl text-white"
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                    />
                </div>
              </button>
              <button
                className="text-white hover:bg-[#ff5f5f] px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => handleScrollToSection('contact')}
              >
                <div className='font-bold flex flex-row-reverse justify-center items-center gap-2'>
                    <span>Contact</span>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="fa-fw text-2xl text-white"
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                    />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;