import React from 'react';
import '../App.css';

function FullImageModal({ image, closeModal }) {
  return (
    <div className="modal-overlay" onClick={closeModal}>
      <img src={image} alt="Full Size" className="modal-image" />
      <button className="close-btn" onClick={closeModal}>X</button>
    </div>
  );
}

export default FullImageModal;
