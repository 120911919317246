import './App.css';
import mobileheader from './images/mobileheader.jpg';
import aboutphoto from './images/mobileaboutphoto.jpg';
import niceBackground from './images/podiumPicture.jpg'
import sponsorsBanner from './images/sponsorsPhoto.jpg';
import selfie from './images/selfie.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faHandshake} from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useEffect, useState } from 'react';
import Contact from './components/Contact.js';
import Navbar from './components/Navbar.js';
import Carousel from './components/Carousel.js'

function App() {
  const contactRef = useRef(null); 
  const polyakRef = useRef(null); // Ref for POLYAK RACING
  const aboutRef = useRef(null);
  const sponsorsRef = useRef(null);

  const [polyakVisible, setPolyakVisible] = useState(false);

  const handleScrollToSection = (section) => {
    if (section === 'about' && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'sponsors' && sponsorsRef.current) {
      sponsorsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'contact' && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target === polyakRef.current) {
          setPolyakVisible(true); // Set the visibility state for POLYAK RACING
        }
      });
    });

    if (polyakRef.current) observer.observe(polyakRef.current);
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <Navbar handleScrollToSection={handleScrollToSection} />
      <header className="relative">
        <img src={mobileheader} className="w-[100%] h-[650px] object-cover lg:h-[600px]" alt=""></img>
        <div className="absolute inset-0 flex justify-center items-center pt-[350px]">
          <div className={`bg-[#FF3C3C] w-[300px] md:w-[400px] py-3 text-center text-white font-bold rounded-full text-3xl md:text-4xl border-[6px] border-[#FF7878] flex flex-col justify-center items-center lg:mb-[100px] ${polyakVisible ? 'animate-drop-in' : ''}`} ref={polyakRef}>
            <div className="w-[200px] md:w-[300px] h-1 bg-white"></div>
            <span className="py-3">POLYAK RACING</span>
            <div className="w-[200px] md:w-[300px] h-1 bg-white"></div>
          </div>
        </div>
      </header>

      {/* About Section */}
    <section ref={aboutRef}>
        <div className="relative">
            <img src={aboutphoto} className="w-full h-[700px] object-cover blur-sm" alt="" />
            <div className="absolute inset-0 bg-black bg-opacity-50 text-white flex items-center">
              {/* MOBILE */}
                <div className="lg:hidden">
                    {/* about */}
                    <div className="flex flex-col justify-center items-center gap-10 text-center">

                        <h1 className="text-4xl font-bold">About Gabriel Polyak</h1>
                        <h1 className="text-md sm:text-lg lg:text-xl font-medium text-center w-[90%]">Despite beginning his motorsport journey later than most, at 14, Gabe has quickly demonstrated his natural talent and determination. Starting in June 2024, he wasted no time making an impact on his career, securing two podium finishes and earning the 2024 Rookie of the Year award from EDKRA. Now, as he prepares to step up to the highly competitive senior Rotax category in 2025, Gabe is more focused than ever on pushing his limits. With a clear goal of competing at the national level, he’s ready to rise to the challenge and solidify his place as a rising star in the motorsport world.</h1>
                        <button onClick={() => handleScrollToSection('contact')} className="w-72 h-14 bg-[#FF5F5F] border-white border-[2px] rounded-xl flex flex-row justify-center items-center gap-5 active:bg-[#ff4444] hover:bg-[#ff4444] transition-colors">
                            <span><FontAwesomeIcon icon={faPaperPlane} className="fa-fw text-2xl" /></span>
                            <div className="bg-white w-2 h-2 rounded-full"></div>
                            <span className="text-2xl uppercase font-semibold">Reach Out</span>
                        </button>

                    </div>
                </div>
                {/* DESKTOP */}
                <div className=" hidden lg:flex lg:flex-row lg:items-center lg:justify-center lg:gap-16 lg:w-full lg:h-full">
                    {/* About */}
                    <div className="flex flex-col justify-center items-center gap-10 text-center w-[50%]">
                    <div className="flex flex-col items-center">
              <div className="flex flex-row justify-center items-center gap-5">
                <div className="bg-white w-[40px] sm:w-[50px] h-1"></div>
                <h1 className="text-4xl font-bold">About Gabriel Polyak</h1>
                <div className="bg-white w-[40px] sm:w-[50px] h-1"></div>
              </div>
            </div>
                        <h1 className="text-lg lg:text-xl xl:text-2xl font-medium text-center w-[90%] lg:w-[80%]">Despite beginning his motorsport journey later than most, at 14, Gabe has quickly demonstrated his natural talent and determination. Starting in June 2024, he wasted no time making an impact on his career, securing two podium finishes and earning the 2024 Rookie of the Year award from EDKRA. Now, as he prepares to step up to the highly competitive senior Rotax category in 2025, Gabe is more focused than ever on pushing his limits. With a clear goal of competing at the national level, he’s ready to rise to the challenge and solidify his place as a rising star in the motorsport world.</h1>
                        <button onClick={() => handleScrollToSection('contact')} className="w-72 h-14 bg-[#FF5F5F] border-white border-[2px] rounded-xl flex flex-row justify-center items-center gap-5 active:bg-[#ff4444] hover:bg-[#ff4444] transition-colors">
                            <span><FontAwesomeIcon icon={faPaperPlane} className="fa-fw text-2xl" /></span>
                            <div className="bg-white w-2 h-2 rounded-full"></div>
                            <span className="text-2xl uppercase font-semibold">Reach Out</span>
                        </button>
                    </div>
                    <div class="w-0.5 h-[500px] bg-white"></div>
                    {/* Hello Section */}
                    <div className="flex justify-center items-center w-[20%]">
                        <img className="w-[90%] object-cover h-[520px] border-white border-[5px] rounded-full moving-image" src={selfie} alt="Selfie" />
                    </div>
                </div>
            </div>
        </div>
    </section>


      {/* Carousel Section */}
      <section ref={aboutRef}>
  <div className="relative">
    <img src={niceBackground} className="w-full h-[700px] object-cover blur-sm" alt="" />
    <div className="absolute inset-0 bg-black bg-opacity-50 text-white flex items-center flex-col justify-center md:flex-row">
      <div className="flex flex-col items-center justify-center mt-5 md:mt-0">
        <div className="flex flex-row justify-center items-center gap-5">
          <div className="bg-white w-[40px] sm:w-[50px] h-1"></div>
          <h1 className="text-4xl font-bold">OUR JOURNEY</h1>
          <div className="bg-white w-[40px] sm:w-[50px] h-1"></div>
        </div>
        <h1 className="font-medium text-sm sm:text-lg text-center mb-6">
          \\ Explore the moments that have shaped our path //
        </h1>
      </div>
      <div className="w-full sm:w-11/12 md:w-3/4 lg:w-1/2">
        <Carousel />
      </div>
    </div>
  </div>
</section>


      {/* Sponsors Section */}
      <section ref={sponsorsRef}>
        <div className="relative">
          <img src={sponsorsBanner} className="w-full h-[500px] object-cover blur-sm" alt=""></img>

          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col pt-10 text-white">
            <div className="flex flex-col items-center">
              <div className="flex flex-row justify-center items-center gap-5">
                <div className="bg-white w-[40px] sm:w-[50px] h-1"></div>
                <h1 className="text-4xl font-bold">SPONSORS</h1>
                <div className="bg-white w-[40px] sm:w-[50px] h-1"></div>
                {/* <FontAwesomeIcon
                    icon={faCrown}
                    className="relative fa-fw text-2xl h-[60px] bottom-[280px] left-[770px] text-yellow-300 rotate-[17deg]"
                  /> */}
              </div>
              <h1 className="font-medium">
                \\ Our Partners and Supporters //
              </h1>
            </div>
            <div className="text-center flex justify-center items-center gap-5 font-bold text-3xl mt-[140px] animate-bounce">
              <span><FontAwesomeIcon icon={faHandshake} className="fa-fw text-2xl" /></span>
              <span className="w-2 h-2 bg-white rounded-full"></span>
              <h1 class="">Be The First!</h1>
            </div>
          </div>
        </div>
      </section>

      <div ref={contactRef}>
        <Contact />
      </div>
    </div>
  );
}

export default App;
