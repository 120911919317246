import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import podiumPicture from '../images/podiumPicture.jpg';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const formRef = useRef(null); // Ref for the form

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let validationErrors = {};
    const formData = new FormData(event.target);
  
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");
  
    // Validation
    if (!name) {
      validationErrors.name = '*Name is required';
    }
  
    if (!email) {
      validationErrors.email = '*Email is required';
    } else if (!validateEmail(email)) {
      validationErrors.email = '*Please enter a valid email';
    }
  
    if (!message) {
      validationErrors.message = '*Message is required';
    }
  
    // Set validation errors or proceed with form submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({}); // Clear errors if validation passes
      setShowModal(true);
      // Append additional data for submission
      formData.append("access_key", "e6d45612-27b5-4914-8c4a-ea0f1977ea12");
      
      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);
  
      try {
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
  
        if (res.success) {
          console.log("Success", res);
        } else {
          console.log("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form", error);
      }
    }
  };
  
  // Intersection Observer for animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('drop-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    const formElements = formRef.current.querySelectorAll('.transition-field');
    formElements.forEach((el) => observer.observe(el));

    return () => {
      formElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <section>
      <div className="relative">
        <img
          src={podiumPicture}
          className="w-full h-[1300px] sm:h-[1100px] lg:h-[900px] object-cover blur-sm"
          alt="podium"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center text-white pt-7">
          
          {/* Flex Row containing description and form */}
          <div className="flex flex-col lg:flex-row justify-center items-center w-full lg:w-[80vw] mt-8">
            
            {/* Description */}
            <div className="flex flex-col justify-center items-center text-center lg:w-[40%] w-[90%] mb-8 lg:mb-0 bg-black p-10 bg-opacity-50 rounded-3xl">
              <div className='flex flex-row justify-center items-center gap-5'>
                <div className='bg-white w-[70%] h-1'></div>
                <h1 className='text-4xl font-bold'>Get in touch!</h1>
                <div className='bg-white w-[70%] h-1'></div>
              </div>
              <p className="text-xl mt-4">
                We’d love to hear from you! Whether you have a question about karting, team inquiries, sponsorship opportunities, or want to learn more about our racing journey, don’t hesitate to get in touch.
              </p>
            </div>

            {/* Vertical Line */}
            <div className="hidden lg:block lg:h-[500px] border-l-2 border-white opacity-80 mx-8"></div>

            {/* Form */}
            <form
              ref={formRef} // Attach ref here
              className="flex justify-center items-center flex-col w-full lg:w-[60%]"
              onSubmit={handleSubmit}
            >
              <div className="transition-field flex flex-col mb-4 opacity-0 translate-y-10 w-[90%] lg:w-[80%]">
                <h1 className="text-3xl font-bold text-center">Contact Now!</h1>
                <label>Name</label>
                <div className="flex flex-row">
                  <span>
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="fa-fw text-2xl bg-white h-[60px] pl-2 text-[#d6d6d6]"
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                    />
                  </span>
                  <input
                    className="bg-white h-[60px] w-full pl-5 focus:outline-none text-black"
                    type="text"
                    name="name"
                    placeholder="Enter your Name"
                    style={{ borderRadius: '0px 10px 10px 0px' }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                {errors.name && (
                  <p className="bg-[#FF5F5F] text-white text-sm mt-1 p-2 rounded-md">
                    {errors.name}
                  </p>
                )}
              </div>

              <div className="transition-field flex flex-col mb-4 opacity-0 translate-y-10 w-[90%] lg:w-[80%]">
                <label>Email</label>
                <div className="flex flex-row">
                  <span>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="fa-fw text-2xl bg-white h-[60px] pl-2 text-[#d6d6d6]"
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                    />
                  </span>
                  <input
                    className="bg-white h-[60px] w-full pl-5 focus:outline-none text-black"
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                    style={{ borderRadius: '0px 10px 10px 0px' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {errors.email && (
                  <p className="bg-[#FF5F5F] text-white text-sm mt-1 p-2 rounded-md">
                    {errors.email}
                  </p>
                )}
              </div>

              <div className="transition-field flex flex-col mb-4 opacity-0 translate-y-10 w-[90%] lg:w-[80%]">
                <label>Message</label>
                <div className="relative">
                  <textarea
                    className="bg-white w-full h-[200px] pl-5 py-2 pt-4 focus:outline-none text-black"
                    name="message"
                    placeholder="Enter your Message"
                    style={{ borderRadius: '10px' }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                {errors.message && (
                  <p className="bg-[#FF5F5F] text-white text-sm mt-1 p-2 rounded-md">
                    {errors.message}
                  </p>
                )}
              </div>
              <input
                type="submit"
                className="transition-field text-2xl bg-[#FF5F5F] font-semibold uppercase h-[65px] opacity-0 border-white border-[2px] translate-y-10 w-[300px] lg:w-[500px] rounded-lg text-white mt-10 hover:cursor-pointer active:bg-[#ff4444] hover:bg-[#ff4444]"
                value="Send!"
              />
            </form>
          </div>
          <h1 className='uppercase mt-[80px]'>© 2024 ~ polyakracing.ca</h1>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="modal-content bg-white rounded-lg p-8 w-[90%] lg:w-[500px] text-center border-[#FF5F5F] border-[5px]">
            <h2 className="text-3xl font-bold text-[#FF5F5F]">Success!</h2>
            <p className="text-lg mt-4">Your message has been successfully sent. We will get back to you shortly!</p>
            <button
              className="bg-[#FF5F5F] text-white mt-6 px-4 py-2 rounded-lg text-xl active:bg-[#ff4444] hover:bg-[#ff4444]"
              onClick={() => setShowModal(false)} // Close modal
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Contact;
